// src/redux/internet/slice.js
import { createSlice } from "@reduxjs/toolkit";

const initState = {
  isOnline: navigator.onLine, // Initialize with current status
};

export const internetSlice = createSlice({
  name: "internet",
  initialState: initState,
  reducers: {
    setOnline: (state) => {
      state.isOnline = true;
    },
    setOffline: (state) => {
      state.isOnline = false;
    },
  },
});

export const { setOnline, setOffline } = internetSlice.actions;
export default internetSlice.reducer;
