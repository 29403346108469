import { combineReducers } from "redux";
import { linkInfoSlice } from "./linkInfo/slice";
import { assessmentSlice } from "./assessment/slice";
import { flowControlSlice } from "./flowControl/slice";
import { languageSlice } from "../pages/LanguageSelection/languageSlice";
import { internetSlice } from "./internetConnectivity/slice";

export const rootReducer = combineReducers({
  linkInfo: linkInfoSlice.reducer,
  assessment: assessmentSlice.reducer,
  flowControl: flowControlSlice.reducer,
  language: languageSlice.reducer,
  internet: internetSlice.reducer,
});
