import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOnline, setOffline } from "../redux/internetConnectivity/slice";

const NetworkListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnline = () => {
      //   console.log("🔵 Internet is back ONLINE");
      dispatch(setOnline());
    };

    const handleOffline = () => {
      //   console.log("🔴 Internet is OFFLINE");
      dispatch(setOffline());
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // console.log(
    //   `⚡ Initial internet status: ${navigator.onLine ? "Online" : "Offline"}`
    // );

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [dispatch]);

  return null;
};

export default NetworkListener;
