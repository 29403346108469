import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_ENV === "production") {
  // Disable console logs in production
  window.console.log = window.console.error = window.console.warn = () => {};
}

async function enableMocking() {
  if (process.env.REACT_APP_MOCKING !== "ENABLED") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  return worker.start();
}

enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
});
