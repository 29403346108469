import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";

import { router } from "./router";
import { customTheme } from "./assets/themes/MUI-override/customTheme";

import "react-toastify/dist/ReactToastify.css";

import AuthProvider from "./contexts/AuthProvider";
import NetworkListener from "./utils/NetworkListener";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <ToastContainer />
      <NetworkListener />
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      <Toaster
        toastOptions={{
          position: "top-right",
          style: {
            background: " #7b27f0",
            color: "#fff",
          },
        }}
      />
    </ThemeProvider>
  );
}

export default App;
